import { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import Hamburger from "../Header/hamburger";
import { useDispatch, useSelector } from "react-redux";
import { resetOffCanvasView, selectHamburgerIntent, updateOffCanvasView } from "@/store/slices/offCanvas";
import { isMobile } from "react-device-detect";

const RightSidebar = () => {
  const sidebarOpen = useSelector(selectHamburgerIntent);
  const dispatch = useDispatch();
  const [mobileView, setIsMobileView] = useState<boolean>(true);

  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = "hidden";
      return;
    };

    document.body.style.overflow = "visible";
  }, [sidebarOpen]);

  useEffect(() => {
    setIsMobileView(isMobile);
  }, [])
  

  const sideBarProps: any = {};
  if (!mobileView) {
    sideBarProps.pullRight = true;
  }

  return <Sidebar
    {...sideBarProps}
    rootClassName={`!fixed z-[1045] ${sidebarOpen ? "visible" : "invisible"}`}
    overlayClassName="!bg-black/70"
    sidebarClassName="w-80 sm:w-96 bg-body-bg"
    sidebar={<Hamburger />}
    open={sidebarOpen}
    onSetOpen={(open) => open ? dispatch(updateOffCanvasView({ name: "HAMBURGER" })) : dispatch(resetOffCanvasView())}
  >
    <></>
  </Sidebar>
};

export default RightSidebar;