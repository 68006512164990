
import dynamic from "next/dynamic";
import RightSidebar from "../Sidebar/Sidebar";
import { useRouter } from "next/router";
import { useMemo } from "react";
import HamburgerButton from "./HamburgerButton";

const Search = dynamic(() => import("./Search"));
const Profile = dynamic(() => import("./Profile"));

const SearchHeader = () => {
  const router = useRouter();
  const searchBox = useMemo(() => {
    const isHomePage = router.asPath === "/" || router.asPath === "/home";
    if (isHomePage) return <></>;
    return <Search />;
  }, [router.asPath])

  return searchBox;
}

const RightIcons = () => {

  return (
    <div className="w-max flex flex-wrap content-center items-center gap-1 sm:gap-4">
      <SearchHeader />
      <Profile />
      {/* <ThemeChangeButton /> */}
      <HamburgerButton 
        id="desktop-hamburger-btn"
        className="!hidden sm:!block"
      />
      <RightSidebar />
    </div>
  );
}

export default RightIcons;